import React from "react";
import "./Footer3.css";

const Footer3 = () => {
  return (
    <div className="footer3">
      <p className="footer3_message">2021 &copy; Laundry</p>
    </div>
  );
};

export default Footer3;
