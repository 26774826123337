import React from "react";
import SigninHeader from "./Components/Header/SigninHeader";
import RegisterPage from "./Components/RegisterPage/RegisterPage";
import Footer1 from "./Components/Footer1/Footer1";
import Footer2 from "./Components/Footer2/Footer2";
import Footer3 from "./Components/Footer3/Footer3";

const RegisterComp = () => {
  return (
    <div>
      <SigninHeader />
      <RegisterPage />
      <Footer1 />
      <Footer2 />
      <Footer3 />
    </div>
  );
};

export default RegisterComp;
